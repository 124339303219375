html,
body {
  position: relative;
  height: 100%;
  overflow: hidden;
}

body{
  background-image: linear-gradient( 109.6deg,  rgba(62,161,219,1) 11.2%, rgba(93,52,236,1) 100.2% );
}

#app{
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

#loading {
  position: relative;
  top: 30%;
  width: 100%;
  text-align: center;
  font-size: 3rem;
  font-weight: bolder;
  color: white;
}

#cam {
  position: absolute;
  top: 5px;
  right: 5px;
  bottom: 20px;
  left: 5px;
}